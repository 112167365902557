let newUnitCount = 1;
document.addEventListener('DOMContentLoaded', function (event) {
    event.preventDefault();

    $('#add_unit').click(function (event) {
        event.preventDefault();
        let cloned = $('#unit_template').clone().attr('id', 'unit_new_' + newUnitCount++).show().appendTo('#edit-unit-table tbody');
        cloned.find(':input').each(function() {
            $(this).prop('disabled', false);
            let name = $(this).prop('name');
            $(this).prop('name', name.replace('[]', '[' + unitNr + ']'));
            if ($(this).prop('id') === '#new_input_line') {
                $(this).prop('value', 1);
            }
        });
        unitNr++;
    });

    $('form :input').change(function () {
        let totals = {};
        $(this).addClass('field-edited');
        $('form :input').each(function () {
            if (this.id) {
                let input = parseInt(this.value);
                let name = this.id.toString();
                if (input && (name.includes('percentage') || name.includes('surface') || name.includes('advance'))) {
                    totals[this.id] = (totals[this.id] || 0) + input;
                }
            }
        });

        if (totals) {
            Object.entries(totals).forEach(entry => {
                let [fieldName, fieldValue] = entry;
                if (fieldName.includes('percentage')) {
                    if (fieldValue === 100) {
                        $('#totals_' + fieldName).removeClass('percentage-error is-invalid').addClass('percentage-okay');
                    } else {
                        $('#totals_' + fieldName).removeClass('percentage-okay is-invalid').addClass('percentage-error');
                    }
                    fieldValue += ' %';
                }
                else if (fieldName.includes('surface')) {
                    fieldValue += ' m²';
                }
                else if (fieldName.includes('advance')) {
                    fieldValue = '€ ' + fieldValue + ',-';
                }
                $('#totals_' + fieldName).text(fieldValue);
            });

            if ((totals.elec_percentage === 100) && (totals.gas_percentage === 100)) {
                $('#submit-button').prop('disabled', false);
            } else {
                //$('#submit-button').prop('disabled', true);
            }
        }
    });
});