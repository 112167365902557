require('./_admin-bar');
require('../debug');

// branch_admin
document.addEventListener('DOMContentLoaded', function () {

    let oldPartnerNr = '';

    $('#is_branch_admin').on('change', function () {
        if ($(this).is(':checked'))
        {
            oldPartnerNr = $('#partnernr').val();
            $('#partnernr').val('*').attr('readonly', true);
            $('#branch-wrapper').show();
        }
        else
        {
            $('#partnernr').val(oldPartnerNr).attr('readonly', false);
            $('#branch-wrapper').hide();
        }
    });

});